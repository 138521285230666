import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
class Checkbox extends Component {
  render() {
    let { value, onChange } = this.props.input;
    return (
      <div className='field is-horizontal'>
        <div class="field-label is-small">
          <label for={this.props.label} class="label">{this.props.label}</label>
        </div>
        <div class="field-body">
          <div class="control">
            <input
              id={this.props.label}
              className='checkbox-input'
              type="checkbox" checked={value ? 'checked' : ''}
              onChange={e => { value = !value; onChange(value) }} />
            {this.props.tooltip && <i class="fas fa-question-circle" data-tip={this.props.tooltip}></i>}
          </div>
        </div>
        <ReactTooltip className='field_tooltip' place='left' effect='solid' offset={{ top: 0 }} multiline={true} />
      </div>
    )
  }
}
export default Checkbox;