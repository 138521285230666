export const authFreeRoutes = [
  '/trigger/',
  '/login',
  '/signup',
  '/reset',
  '/confirm',
  '/logout',
  '/appsumo',
  '/lifetime'
];

export function shouldRedirectLogin(history) {
  let redirect = true;
  authFreeRoutes.forEach((route) => {
    if (history.location.pathname.includes(route)) {
      redirect = false;
    }
  });
  if (redirect) {
    history.push('/login');
  }
}
