import axios from 'axios'
import history from '../utils/history'
import axiosRetry from 'axios-retry';
import { toast } from 'react-toastify';
import store from '../redux/store'
import { updateAccessToken } from 'redux/actions'
import { shouldRedirectLogin } from 'utils/auth'

export const baseURL = process.env.REACT_APP_SERVER_BASE_URL

let client = axios.create({
  baseURL,
  withCredentials: true,

})
client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let retryClient = axios.create({
  baseURL,
  withCredentials: true,
})
retryClient.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axiosRetry(retryClient, { retries: 3, retryDelay: c => c * 500 });

client.interceptors.request.use(
  request => {
    request.headers.authorization = `bearer ${store.getState().auth.accessToken}`;
    return request;
  },
  error => Promise.reject(error)
);

client.interceptors.response.use((response) => { return response },
  async error => {
    const originalRequest = error.config;
    if (!error.response) { return }
    if (error.response.status === 401 && !originalRequest._retry) {

      originalRequest._retry = true;

      try {
        const response = await retryClient(`${baseURL}/auth/refresh_token`, { method: 'post', withCredentials: true })
        if (response.data.success && response.data.accessToken) {

          store.dispatch(updateAccessToken(response.data.accessToken))

          originalRequest.headers.authorization = `bearer ${store.getState().auth.accessToken}`;

          return axios(originalRequest);

        }
      } catch (error) {
        shouldRedirectLogin(history)
      }
    }
    return Promise.reject(error);
  });


export default client