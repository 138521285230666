import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import history from 'utils/history'
import Loader from 'react-loader-spinner'
import { logout } from 'redux/actions'

export default (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logout())
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function navigate() {
      await sleep(1000)
      history.push('/login')
    }
    navigate()
  }, [])
  return (
    <div className='auth_loader_container'>
      <Loader
        type="Oval"
        color="#ccc"
        height={30}
        width={30} />
    </div>
  )
}