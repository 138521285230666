import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { createEditor, Node, Text, Transforms } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

import './InputEditor.scss'

const serialize = value => {
  return (
    value
      // Return the string content of each paragraph in the value's children.
      .map(n => Node.string(n))
      // Join them all with line breaks denoting paragraphs.
      .join('\n')
  )
}

// Define a deserializing function that takes a string and returns a value.
const deserialize = string => {
  // Return a value array of children derived by splitting the string.
  return string.split('\n').map(line => {
    return {
      children: [{ text: line }],
    }
  })
}


const Leaf = ({ attributes, children, leaf }) => {
  return (
    <span
      {...attributes}
      className={`${leaf.highlight ? 'highlight' : ''}`}>
      {children}
    </span>
  )
}

const SlateEditor = props => {
  const [value, setValue] = useState(deserialize(props.value))
  const [highlights] = useState(props.highlights)

  const editor = useMemo(() => withReact(createEditor()), [])

  useEffect(() => {
    setValue(deserialize(props.value))
    const point = { path: [0, 0], offset: props.value.length }
    Transforms.select(editor, { anchor: point, focus: point })
  }, [props.value])

  const decorate = useCallback(
    ([node, path]) => {
      const ranges = []

      if (highlights && Text.isText(node)) {

        const { text } = node
        highlights.forEach((search) => {
          const parts = text.split(search)
          let offset = 0

          parts.forEach((part, i) => {
            if (i !== 0) {
              ranges.push({
                anchor: { path, offset: offset - search.length },
                focus: { path, offset },
                highlight: true,
              })
            }
            offset = offset + part.length + search.length
          })
        })
      }

      return ranges
    },
    [highlights]
  )

  return (
    <div className='input_editor_slate' onClick={props.onClick}>
      <Slate editor={editor} value={value} onChange={value => {
        setValue(value);
        props.onChange(serialize(value))
      }}>
        <Editable
          spellcheck={false}
          decorate={decorate}
          renderLeaf={props => <Leaf {...props} />}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeholder} />
      </Slate>
    </div>
  )
}

export default SlateEditor