export const isActionPending = (state, action) => state.pending[action];
export const getNumPages = (state) => state.documentEditor.numPages;
export const getCurrentPage = (state) => state.documentEditor.currentPage;
export const getRenderedPages = (state) => state.documentEditor.renderedPages;
export const getAnnotation = (state) => state.documentEditor.annotation;
export const getFields = (state) => state.documentEditor.fields;
export const getActiveField = (state) => state.documentEditor.activeField;
export const getLeftPanel = (state) => state.documentEditor.leftPanel;
export const getCurrentSelector = (state) =>
  state.documentEditor.currentSelector;
export const getEditorMode = (state) => state.documentEditor.editorMode;
export const getRenderedPdf = (state) => state.documentEditor.renderedPDF;
export const getRenderedData = (state) => state.documentEditor.renderedData;
export const getPdf = (state) => state.documentEditor.pdf;
export const changesSynced = (state) => state.documentEditor.changesSynced;

export const getUser = (state) => state.auth.user;
export const isLoggedIn = (state) => state.auth.isLoggedIn;
export const getAccessToken = (state) => state.auth.accessToken;
export const getAPIKey = (state) => state.auth.user.apiSecret;
export const getDocuments = (state) => state.documents.documents;
export const getDocumentSchemas = (state) => state.documents.schemas;
export const getDocument = (state, documentId) =>
  state.documents.documents.find((d) => d.id === documentId);
// export const getDocument = state => state.documents.currentDocumentForm

export const getWorkflows = (state) => state.workflows.workflows;
export const getWorkflowHistory = (state) => state.workflows.history;

export const getCurrentStep = (state) => state.workflowEditor.currentStep;
export const getWorkflow = (state, id) =>
  state.workflows.workflows.find((s) => s.id === id);
export const getWorkflowSteps = (state) => state.workflowEditor.workflowSteps;
export const getWorkflowStep = (state, id) =>
  state.workflowEditor.workflowSteps.find((s) => s.id === id);
export const getWorkflowData = (state) => state.workflowEditor.workflowData;

export const isWorkflowValid = (state, id) => {
  let valid = true;
  state.workflowEditor.workflowSteps.map((step) => {
    valid = valid && step.data.valid;
  });
  return valid;
};

export const isWorkflowActive = (state, id) =>
  state.workflowEditor.workflowSteps.find((s) => s.id === id).active;

// app
export const getEmailApps = (state) => state.apps.emails;
export const getDriveApps = (state) => state.apps.drives;
