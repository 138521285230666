import { Annotation } from 'components/DocumentEditor/Annotation/Annotation';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { pdfjs } from 'react-pdf';
import { PDFtoIMG } from 'react-pdf-to-image';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Preview = (props) => {
  const [pdf, setPDF] = useState(null);
  const [error, setError] = useState(null);
  const [loading, isLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [pdfURL, setPdfURL] = useState(null);

  useEffect(() => {
    async function getPDF() {
      if (props.workflowRunId) {
        setPdfURL(
          `${process.env.REACT_APP_SERVER_BASE_URL}/resource/file/${props.workflowRunId}?mode=blob`
        );
      } else {
        setPdfURL(props.pdfURL);
      }
    }
    getPDF();
  }, []);

  useEffect(() => {
    if (pages.length > 0) {
      isLoading(false);
    }
  }, [pages]);

  const Loading = () => (
    <div className="document_preview_loading is-centered">
      <Loader type="Oval" color="#ccc" height={30} width={30} />
    </div>
  );

  let showFields = [];
  if (props.selectedFields) {
    showFields = props.selectedFields.map((f) => f.value);
  }

  const renderDocument = () => {
    //API: https://github.com/wojtekmaj/react-pdf
    return (
      // <Document
      //   file={props.pdfURL}
      //   loading={<Loading />}
      //   noData="Cannot load the PDF. Please refresh the page."
      //   onSourceError={(error) => {
      //     console.error(error);
      //     setError(error.message);
      //   }}
      //   onSourceSuccess={() => {
      //     console.log('Document loaded');
      //   }}
      //   onLoadSuccess={(pdf) => {
      //     setPDF(pdf);
      //   }}
      // >
      //   {pdf && pdf.numPages
      //     ? times(pdf.numPages, (i) => (
      //         <Page
      //           className="page"
      //           pageNumber={i + 1}
      //           key={i}
      //           width={600}
      //           loading={<Loading />}
      //         />
      //       ))
      //     : null}
      // </Document>
      <>
        {pages.map((page, i) => {
          let pagefields = props.fields.filter(
            (f) => f.geometry.page === i + 1 && !f.data.hidden
          );

          return (
            <Annotation
              documentId={123}
              annotations={pagefields.filter((f) => {
                if (
                  props.displayFields &&
                  props.displayFields.value === 'selected'
                ) {
                  return showFields.includes(f.data.name);
                } else return true;
              })}
              src={page}
              displayMode={true}
              currentPage={i + 1}
              // These are dummy props to make Annotation work here.
              isMouseHovering={{ innerRef: () => {} }}
              relativeMousePos={{ x: 0, y: 0, innerRef: () => {} }}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="public_document_preview  is-centered">
      {pdfURL && (
        <PDFtoIMG file={pdfURL}>
          {({ pages }) => {
            setPages(pages);
            return <></>;
          }}
        </PDFtoIMG>
      )}
      {loading ? <Loading /> : renderDocument()}
    </div>
  );
};

export default Preview;
