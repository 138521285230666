import server from 'api/server';
import { useFormik } from 'formik';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { login, logout } from 'redux/actions';
import { isActionPending, isLoggedIn } from 'redux/selectors';
import history from 'utils/history';
import * as Yup from 'yup';

const SVSignup = (props) => {
  const { invite } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });

  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState('signup');
  const [authUri, setAuthUri] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [userEmail, setUserEmail] = useState('');

  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    let ignore = false;
    // get user details from invite token which is the user id
    const getUserDetails = async () => {
      if (!invite) return;
      setLoading(true);
      try {
        const { data } = await server.get(`/auth/invite/${invite}`);
        if (data) {
          formik.setFieldValue('name', data.name);
          formik.setFieldValue('email', data.email);
          formik.setFieldValue('companyName', data.companyName);
          formik.setFieldTouched('name', false);
          formik.setFieldTouched('email', false);
          formik.setFieldTouched('companyName', false);

          if (!ignore) {
            setName(data.name);
            setEmail(data.email);
            setCompanyName(data.companyName);
          }
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    getUserDetails();
    return () => {
      ignore = true;
    };
  }, [invite]);

  const onSVSubmit = async () => {
    const { email } = formik.values;
    setUserEmail(email);
    try {
      setError('');
      setLoading(true);
      const { data } = await server.post('/apps/smartvault', { email });
      setLoading(false);
      if (!data.exists) {
        setError('Cannot find a SmartVault user with this email address!');
      } else if (!data.authorized) {
        setAuthUri(data.authorizationUri);
        setForm('authorize');
      } else if (!data.accountSelected && !selectedAccount) {
        setAccounts(data.accounts);
        setForm('selectAccount');
      } else {
        await signUp();
        setAuthUri(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signUp = async () => {
    const { name, email, password, companyName } = formik.values;

    // Create account in Docdown.
    let userEmail = null;
    let userId = null;
    try {
      setLoading(true);
      const { data } = await server.post(`/auth/signup`, {
        name,
        email,
        companyName,
        password,
        svCustomer: true
      });
      userEmail = data.email;
      userId = data.userId;
    } catch (err) {
      setForm('signup');
      setLoading(false);
      if (err.response && err.response.data) {
        setLoading(false);
        setError(err.response.data);
      }
      return;
    }
    try {
      setLoading(true);
      // Add SV details to Docdown.
      const { data } = await server.post('/apps/smartvault', {
        email: userEmail,
        userId,
        hidden: true,
        account: selectedAccount
      });
      dispatch(logout());
      dispatch(login(email, password, true));
    } catch (err) {
      setForm('signup');
      setLoading(false);
      if (err.response && err.response.data) {
        setLoading(false);
        setError(err.response.data);
      }
      return;
    }
    setLoading(false);
  };

  const onVerify = async () => {
    try {
      setError('');
      setLoading(true);
      const { data } = await server.post('/apps/smartvault', {
        email: userEmail
      });
      setLoading(false);
      if (data.authorized && data.accountSelected) {
        await signUp();
        setAuthUri(null);
      } else if (data.accounts && !selectedAccount) {
        setAccounts(data.accounts);
        setForm('selectAccount');
      } else if (selectedAccount) {
        await signUp();
        setAuthUri(null);
      } else {
        setError(
          'Docdown is not authorized to access your SmartVault account yet!'
        );
      }
    } catch (err) {
      setLoading(false);

      if (err?.response?.data?.message && err?.response?.status === 400) {
        setError(err.response.data.message);
      }
      console.log(err);
    }
  };

  const onAccountSelect = async () => {
    try {
      if (selectedAccount) setError('');
      else {
        setError('Please select an account');
        return;
      }
      const account = accounts.find((a) => a.id == selectedAccount.value);
      setLoading(true);
      const { data } = await server.post('/apps/smartvault', {
        email: userEmail,
        account
      });
      if (data.authorized && data.accountSelected) {
        setAuthUri(null);
        await signUp();
      } else {
        throw Error('Could not add Smartvault Account');
      }
    } catch (err) {
      setLoading(false);

      if (err?.response?.data?.message && err?.response?.status === 400) {
        setError(err.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      password: '',
      companyName: ''
    },
    onSubmit: onSVSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/,
          'Password must have at least 1 uppercase, 1 lowercase, 1 number, 1 symbol and minimum 12 characters!'
        ),
      companyName: Yup.string().required('Organization name is required')
    })
  });

  const loggedIn = useSelector(isLoggedIn);
  const pendingLogin = useSelector((state) => isActionPending(state, 'LOGIN'));

  useEffect(() => {
    async function navigate() {
      if (loggedIn && pendingLogin && !pendingLogin.pending) {
        history.push(`/${props.location.search}`);
      }
    }
    navigate();
  }, [loggedIn, pendingLogin]);

  const renderLoading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  const renderSignupForm = () => {
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <h4 className="title is-5">
            {invite ? 'Set Up your Login' : 'Create an account'}
          </h4>

          <div class="field">
            <label class="label">Full Name</label>
            <div className="control">
              <input
                name="name"
                class="input"
                onChange={formik.handleChange}
                value={formik.values.name}
                disabled={!!name}
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Organization Name</label>
            <div className="control">
              <input
                name="companyName"
                class="input"
                onChange={formik.handleChange}
                value={formik.values.companyName}
                disabled={!!companyName}
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Email</label>
            <div className="control">
              <input
                name="email"
                class="input"
                onChange={formik.handleChange}
                value={formik.values.email}
                disabled={!!email}
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div className="control">
              <input
                name="password"
                class="input"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            </div>
          </div>

          <p className="help is-danger">{error && <span>{error}</span>}</p>

          {formik.errors &&
            Object.keys(formik.errors).map((error) => (
              <p className="help is-danger">
                <span>{formik.errors[error]}</span>
              </p>
            ))}

          <br />
          <div class={`field`}>
            <button
              type="submit"
              class={`button is-fullwidth is-info ${loading && 'is-loading'}`}
            >
              {invite ? 'Set Up Login' : 'Create an account'}
            </button>
          </div>
        </form>
      </>
    );
  };

  const renderAuthorization = () => {
    return (
      <div class="form">
        <h4 class="title is-h4">Authenticate SmartVault Account</h4>
        <p>
          Please click on the following button to allow Docdown to access your
          SmartVault account.
        </p>
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={() => {
                window.open(authUri, '_blank').focus();
                setForm('verify');
              }}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Authenticate Docdown
            </button>
          </div>
          <div class="control"></div>
        </div>
      </div>
    );
  };
  const renderVerify = () => {
    return (
      <div class="form">
        <h4 class="title is-h3">Verify SmartVault Account</h4>
        <p>
          After allowing Docdown to access your account, please click on the
          verify button below.
        </p>
        {error && <p class="help is-danger">{error}</p>}
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={onVerify}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Verify
            </button>
          </div>
          <div class="control"></div>
        </div>
      </div>
    );
  };
  const renderSelectAccount = () => {
    return (
      <div class="form">
        <h4 class="title is-h3">Select SmartVault Account</h4>
        <p>
          This user is connected to multiple SmartVault accounts. Please select
          the account you want to use in this integration.
        </p>
        <Select
          options={accounts.map((a) => ({
            value: a.id,
            label: a.name,
            features: a.features,
            id: a.id,
            orgId: a.orgId
          }))}
          onChange={setSelectedAccount}
        />
        {error && <p class="help is-danger">{error}</p>}
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={onAccountSelect}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Select
            </button>
          </div>
          <div class="control"></div>
        </div>
      </div>
    );
  };

  const render = () => {
    switch (form) {
      case 'signup':
        return renderSignupForm();
      case 'verify':
        return renderVerify();
      case 'authorize':
        return renderAuthorization();
      case 'selectAccount':
        return renderSelectAccount();
      default:
        return renderSignupForm();
    }
  };

  return (
    <div className="login">
      <div class="columns is-mobile is-centered is-vcentered">
        <div class="column is-half is-desktop is-narrow">
          <div className="brand">
            <img src="/assets/docdown-logo.png" />
          </div>

          <section class="section">
            <div className="box">{loading ? renderLoading() : render()}</div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SVSignup;
