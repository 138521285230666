import useOnClickOutside from 'hooks/useOnClickOutside';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import { logout } from 'redux/actions';
import { getUser, isLoggedIn } from 'redux/selectors';
import history from 'utils/history';
import './Page.scss';

const SidebarComp = (props) => {
  const user = useSelector(getUser);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  const location = useLocation();
  const [history, setHistory] = useState(
    location.pathname.includes('/history')
  );
  const toggleHistory = () => setHistory(!history);
  const mainRoutes = () => {
    return (
      <React.Fragment>
        <Link to="/">
          <div
            className={`menu-item ${
              location.pathname === '/' ? 'active_page' : ''
            }`}
          >
            <span>
              <i className="fas fa-home"></i>Dashboard
            </span>
          </div>
        </Link>
        <Link to="/documents">
          <div
            className={`menu-item ${
              location.pathname.includes('/documents') ? 'active_page' : ''
            }`}
          >
            <span>
              <i className="fas fa-file"></i> Documents
            </span>
          </div>
        </Link>
        <Link to="/workflows">
          <div
            className={`menu-item ${
              location.pathname.includes('/workflows') ? 'active_page' : ''
            }`}
          >
            <span>
              <i className="fas fa-stream"></i> Workflows
            </span>
          </div>
        </Link>
        {['owner', 'admin'].includes(user.role) && (
          <Link to="/activity_log">
            <div
              className={`menu-item ${
                location.pathname.includes('/activity_log') ? 'active_page' : ''
              }`}
            >
              <span>
                <i className="fas fa-history"></i> Activity Log
              </span>
            </div>
          </Link>
        )}
      </React.Fragment>
    );
  };
  const settingsRoutes = () => {
    return (
      <React.Fragment>
        <Link to="/integrations">
          <div
            className={`menu-item ${
              location.pathname.includes('/integrations') ? 'active_page' : ''
            }`}
          >
            <span>
              <i className="fas fa-th"></i>Integrations
            </span>
          </div>
        </Link>
        <Link to="/settings/account">
          <div
            className={`menu-item ${
              location.pathname.includes('/settings') ? 'active_page' : ''
            }`}
          >
            <span>
              <i className="fas fa-cog"></i>Settings
            </span>
          </div>
        </Link>

        {/* <Link to="/settings/billing">
          <div
            className={`menu-item ${
              location.pathname === '/settings/billing' ? 'active_page' : ''
            }`}
          >
            <span>
              <i className="fas fa-credit-card"></i>Billing
            </span>
          </div>
        </Link> */}
      </React.Fragment>
    );
  };
  return (
    <div className="sidebar_component">
      <div className="sidebar_links">
        <div class="sidebar_logo_container">
          <img src="/assets/docdown.png" class="sidebar_logo" />
          <span class="release_badge">
            {typeof Headway != 'undefined' && (
              <i
                style={{ cursor: 'pointer' }}
                class="fas fa-bell release_trigger"
              ></i>
            )}
          </span>
        </div>
        {mainRoutes()}
        <div className="breaker" />
        {settingsRoutes()}
        <div className="breaker" />
      </div>
      <div className="sidebar_footer">
        <div class={`dropdown is-up ${menuOpen && 'is-active'}`} ref={ref}>
          <div class="dropdown-trigger">
            <a onClick={(e) => setMenuOpen(!menuOpen)}>
              <a onClick={(e) => setMenuOpen(!menuOpen)}>
                <span class="sidebar_footer_text">My Account </span>
                <span class="icon is-small angle_icon">
                  <i class="fas fa-angle-up" aria-hidden="true"></i>
                </span>
                <span class="icon is-small user_icon">
                  <i class="fas fa-user-circle" aria-hidden="true"></i>
                </span>
              </a>
            </a>
            <div class="dropdown-menu" id="dropdown-menu2" role="menu">
              <div class="dropdown-content">
                <div className="dropdown-item">
                  <p>
                    <strong>{user.name}</strong>
                  </p>
                  <p>{user.email}</p>
                </div>
                <Link
                  to="/settings/account"
                  class="dropdown-item"
                  onClick={(e) => {
                    setMenuOpen(false);
                  }}
                >
                  <span class="icon">
                    <i class="fas fa-cog" aria-hidden="true"></i>
                  </span>
                  <span>Settings</span>
                </Link>
                <Link
                  to="/logout"
                  class="dropdown-item"
                  onClick={(e) => {
                    dispatch(logout());
                    setMenuOpen(false);
                  }}
                >
                  <span class="icon">
                    <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                  </span>
                  <span>Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navbar = (props) => {
  const user = useSelector(getUser);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  const loggedIn = () => {
    const renderLoggedOut = () => {
      return <div class="navbar-item"></div>;
    };

    const renderLoggedIn = () => {
      return (
        <>
          <div class="navbar-item release_badge">
            <div class="">
              {/* <span class="icon ">
                <i class="far fa-bell" aria-hidden="true"></i>
              </span> */}
            </div>
          </div>
          <div class="navbar-item">
            <div
              class={`dropdown is-right ${menuOpen && 'is-active'}`}
              ref={ref}
            >
              <div class="dropdown-trigger">
                <a onClick={(e) => setMenuOpen(!menuOpen)}>
                  <span>My Account</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                  {/* <span class="icon">
                    <i class="fas fa-user" aria-hidden="true"></i>
                  </span> */}
                </a>
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
                  <div class="dropdown-content">
                    <div className="dropdown-item">
                      <p>{user.email}</p>
                    </div>
                    <Link
                      to="/settings/account"
                      class="dropdown-item"
                      onClick={(e) => {
                        setMenuOpen(false);
                      }}
                    >
                      <span class="icon">
                        <i class="fas fa-cog" aria-hidden="true"></i>
                      </span>
                      <span>Settings</span>
                    </Link>
                    <Link
                      to="/logout"
                      class="dropdown-item"
                      onClick={(e) => {
                        dispatch(logout());
                        setMenuOpen(false);
                      }}
                    >
                      <span class="icon">
                        <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                      </span>
                      <span>Logout</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    if (props.loading) {
      return <p>loading...</p>;
    } else {
      return isUserLoggedIn ? renderLoggedIn() : renderLoggedOut();
    }
  };

  return (
    <>
      <nav
        class={`navbar page_nav ${
          props.filters ? 'bottom_border' : 'bottom_shadow'
        }`}
      >
        <div class="navbar-menu">
          <div class="navbar-start">
            <div class="navbar-item">
              <h5 class="title is-5">{props.title}</h5>
            </div>
            <div class="navbar-item navbar-search">{props.search}</div>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              {props.action}
              {/* {loggedIn()} */}
            </div>
          </div>
        </div>
      </nav>
      {props.filters ? (
        <nav
          class={`navbar filter_nav ${
            props.filters ? 'bottom_shadow' : 'bottom_border'
          }`}
        >
          <div class="navbar-start">
            {props.filters.map((Comp) => (
              <div class="navbar-item">{Comp}</div>
            ))}
          </div>
        </nav>
      ) : (
        <nav class="navbar filter_nav bottom_shadow top_border"></nav>
      )}
    </>
  );
};

const Page = (props) => {
  const user = useSelector(getUser);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  useEffect(() => {
    document.title = props.title ? `${props.title} | Docdown` : 'Docdown';
  });

  const location = useLocation();

  useEffect(() => {
    let queries = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (Object.keys(queries).includes('redirect'))
      history.push(queries['redirect']);
  }, []);

  return (
    <div className="page_container columns is-gapless is-marginless is-paddingless is-mobile">
      <div className="nav-bar-column sidebar-column">
        <SidebarComp />
      </div>
      <div className="page_content is-10">
        <Navbar
          title={props.title}
          action={props.action}
          filters={props.filters}
          search={props.search}
        />
        <div className="page_scrollable">{props.children}</div>
      </div>
    </div>
  );
};
export default Page;
