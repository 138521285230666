import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import * as Sentry from '@sentry/react';
import { version } from '../package.json';

import store from './redux/store';
import App from './components/App';

if (process.NODE_ENV === 'production')
  Sentry.init({
    dsn:
      'https://d8a1663270df42a0927becc837a9cec9@o398231.ingest.sentry.io/5378134',
    release: version,
    debug: true,
    beforeSend(event) {
      // eslint-disable-next-line no-console
      console.log('Sending error to Sentry');
      return event;
    }
  });

Modal.setAppElement('#root');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);
