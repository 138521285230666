import server from 'api/server';
import { ForgotForm, ResetForm, VerifyCodeForm } from 'components/Auth/Reset';
import TextField from 'components/_common/FormFields/TextField';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { fetchDocuments, login } from 'redux/actions';
import { fetchWorkflows } from 'redux/actions/workflowActions';
import { isActionPending, isLoggedIn } from 'redux/selectors';
import history from 'utils/history';
import './Login.scss';

const Login = (props) => {
  const dispatch = useDispatch();
  const [view, setView] = useState(props.view || 'login');
  const [reset, setReset] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const pendingState = useSelector((state) => isActionPending(state, 'LOGIN'));
  const pendingDocuments = useSelector((state) =>
    isActionPending(state, 'GET_DOCUMENTS')
  );
  const pendingWorkflows = useSelector((state) =>
    isActionPending(state, 'GET_WORKFLOWS')
  );

  const loggedIn = useSelector(isLoggedIn);

  const recaptchaRef = React.createRef();

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchDocuments());
      dispatch(fetchWorkflows());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    async function navigate() {
      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      if (loggedIn && pendingWorkflows && pendingDocuments) {
        if (pendingWorkflows.pending && pendingDocuments.pending) {
          await sleep(500);
          history.push(`/${props.location.search}`);
        }
      }
    }
    navigate();
  }, [loggedIn, pendingDocuments, pendingWorkflows]);

  const renderloader = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
        <span>Signing in...</span>
      </div>
    );
  };

  const renderLoginForm = () => {
    const { handleSubmit } = props;
    const submit = async (values) => {
      try {
        setLoading(true);
        const response = await server.post('/auth/login', { ...values });
        if (!response) throw Error(`No response from the server!`);
        setLoading(false);
        setEmail(values.email);
        setError(null);
        setView('login-verify2FA');
      } catch (err) {
        if (err.response && err.response.data) {
          setError(err.response.data);
        } else {
          setError(err.message);
        }
        setLoading(false);
      }
    };
    return (
      <form onSubmit={handleSubmit(submit)}>
        <h4 className="title is-5">Log in to your account</h4>
        {reset && (
          <article className="message is-success">
            <div class="message-body ">
              <span className="is-small">
                Your password has been successfully changed
              </span>
            </div>
          </article>
        )}
        <Field
          label="Email"
          class="input"
          validate={[required({ msg: 'Please provide your email address' })]}
          component={TextField}
          type="email"
          name="email"
        />

        <Field
          label="Password"
          validate={[required({ msg: 'Please provide your password' })]}
          component={TextField}
          class="input"
          type="password"
          name="password"
        />

        <p className="help is-danger">{error && <span>{error}</span>}</p>

        <div class={`field`}>
          <button
            type="submit"
            class={`button is-fullwidth is-info ${loading && 'is-loading'}`}
          >
            Continue
          </button>
        </div>
        <span className="login-links">
          <Link to="/reset">Forgot Password? </Link>
          <Link to="/signup">Sign up for an account</Link>
        </span>
      </form>
    );
  };

  const renderVerifyForm = () => {
    const { handleSubmit } = props;
    const submit = (values) => dispatch(login(values.email, values.token));
    return (
      <>
        <form onSubmit={handleSubmit(submit)}>
          <h4 className="title is-5">Verify it's you</h4>
          <p class="help">
            We sent a verification code to your email. Enter the code from the
            email in the field below.
          </p>
          <Field
            label="Verification Code"
            validate={[required({ msg: 'Please provide your password' })]}
            component={TextField}
            class="input"
            name="token"
          />
          <p className="help is-danger">
            {pendingState && pendingState.error && (
              <span>{pendingState.error}</span>
            )}
          </p>

          <div class={`field`}>
            <button
              type="submit"
              class={`button is-fullwidth is-info ${
                pendingState && pendingState.pending && 'is-loading'
              }`}
            >
              Verify Code
            </button>
          </div>
          <span className="login-links">
            <a href="/login">Back to Login</a>
          </span>
        </form>
      </>
    );
  };

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const renderForm = () => {
    if (loggedIn) {
      return renderloader();
    } else {
      switch (view) {
        case 'login':
          return renderLoginForm();
        case 'login-verify2FA':
          return renderVerifyForm();
        case 'forgot':
          return (
            <ForgotForm
              onSuccess={(email) => {
                setEmail(email);
                setView('code');
              }}
              onReturn={(e) => setView('login')}
            />
          );
        case 'code':
          return (
            <VerifyCodeForm
              email={email}
              onSuccess={(code) => {
                setCode(code);
                setView('reset');
              }}
            />
          );
        case 'reset':
          return (
            <ResetForm
              email={email}
              code={code}
              onSuccess={() => {
                setView('login');
                setReset(true);
              }}
            />
          );
        default:
          return renderLoginForm();
      }
    }
  };

  const render = () => {
    return (
      <div className="login">
        <div class="columns is-mobile is-centered is-vcentered">
          <div class="column is-half is-desktop is-narrow">
            <div className="brand">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M22 24h-20l-2-10h24l-2 10zm-1.444-22.001l.439-1.999h-17.994l.439 1.999h17.116zm1.7 4.001l.37-2h-21.256l.371 2h20.515zm-19.731 6l-.254-2h19.45l-.262 2h2.017l.524-4h-24l.509 4h2.016z" />
              </svg>
              <h4>Docdown</h4> */}
              <img src="/assets/docdown-logo.png" />
            </div>
            <section class="section">
              <div className="box">{renderForm()}</div>
            </section>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default reduxForm({ form: 'login' })(Login);
