/* eslint-disable react/jsx-props-no-spreading */
import '@fortawesome/fontawesome-free/css/all.min.css';
import server from 'api/server';
import 'bulma/css/bulma.css';
import AuthRoute from 'components/Auth/Route';
import 'constants/css/styles.scss';
import React, { useEffect } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IntercomProvider } from 'react-use-intercom';
import { refreshToken } from 'redux/actions';
import history from 'utils/history';
import ActivityLog from './ActivityLog';
import Confirm from './Auth/Confirm';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import SVLogin from './Auth/SVLogin';
import SVSignup from './Auth/SVSignup';
import Dashboard from './Dashboard';
import DocumentEditor from './DocumentEditor';
import Documents from './Documents';
import { Document, DocumentHistory, Run, WorkflowHistory } from './History';
import Apps from './Integrations';
import AppSettings from './Integrations/AppSettings';
import Settings from './Settings';
import TriggerDocumentForm from './Trigger/DocumentForm';
import workflowEditor from './WorkflowEditor';
import Workflows from './Workflows';

const App = () => {
  const dispatch = useDispatch();

  // const location = useLocation()
  useEffect(() => {
    dispatch(refreshToken());
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // _refiner('setProject', 'bb93bf20-ca92-11eb-bab2-555a252ea00b');
    }

    async function updateToLatestClientVersion() {
      try {
        const response = await server.get('/client/version');

        if (response.data.version !== process.env.REACT_APP_CLIENT_VERSION) {
          console.log('Client version behind the latest, clearing cache...');
          if ('caches' in window) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const name of names) caches.delete(name);
            });
          }
          // window.location.reload(true)
        } else {
          console.log(
            `Using client build version ${process.env.REACT_APP_CLIENT_VERSION}, latest version is ${response.data.version}`
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
    updateToLatestClientVersion();
  }, []);

  const routes = () => {
    return (
      <Router history={history}>
        <Switch>
          <AuthRoute path="/" exact component={Dashboard} />

          <AuthRoute path="/documents" exact component={Documents} />
          <AuthRoute
            path="/documents/editor/:id"
            exact
            component={DocumentEditor}
          />

          <AuthRoute path="/workflows" exact component={Workflows} />
          <AuthRoute
            path="/workflows/editor/:id"
            exact
            component={workflowEditor}
          />

          <AuthRoute
            path="/activity_log"
            exact
            roles={['owner', 'admin']}
            component={ActivityLog}
          />

          <AuthRoute
            path="/history/workflow"
            exact
            component={WorkflowHistory}
          />
          <AuthRoute
            path="/history/document"
            exact
            component={DocumentHistory}
          />
          <AuthRoute
            path="/history/document/:generatedDocId"
            exact
            component={Document}
          />
          <AuthRoute
            path="/history/workflow/:workflowRunId"
            exact
            component={Run}
          />

          <AuthRoute path="/settings" exact component={Settings} />
          <AuthRoute
            path="/settings/account"
            exact
            component={(routeProps) => (
              <Settings active="Account" {...routeProps} />
            )}
          />
          <AuthRoute
            path="/settings/billing"
            exact
            roles={['owner']}
            component={(routeProps) => (
              <Settings active="Subscription and Billing" {...routeProps} />
            )}
          />
          <AuthRoute
            path="/settings/members"
            exact
            roles={['owner', 'admin']}
            component={(routeProps) => (
              <Settings active="Team Members" {...routeProps} />
            )}
          />
          <AuthRoute
            path="/settings/brands"
            exact
            roles={['owner', 'admin']}
            component={(routeProps) => (
              <Settings active="Brand Identities" {...routeProps} />
            )}
          />

          <AuthRoute path="/integrations" exact component={Apps} />
          <AuthRoute path="/integrations/:id" exact component={AppSettings} />

          <Route path="/login" exact component={SVLogin} />
          <Route
            path="/reset"
            exact
            component={(routeProps) => <Login view="forgot" {...routeProps} />}
          />

          <Route
            path="/secure/signup"
            exact
            component={(routeProps) => <SVSignup {...routeProps} />}
          />
          <Redirect from="/signup" to="/secure/signup" />

          <Route path="/logout" exact component={Logout} />
          <Route path="/confirm/:token" exact component={Confirm} />

          <Route
            path="/trigger/form/:id/:workflowRunId?"
            exact
            component={TriggerDocumentForm}
          />
          <Route
            path="/trigger/preview/:id/:workflowRunId?"
            exact
            component={TriggerDocumentForm}
          />
          {/* This is 404, exact route not found */}
          <Route
            component={() => {
              history.push('/');
              return <Dashboard />;
            }}
          />
        </Switch>
      </Router>
    );
  };
  const render = () => {
    return (
      <>
        {process.env.REACT_APP_ENVIRONMENT === 'production' ? (
          <IntercomProvider appId="bywhr8zc" autoBoot>
            {routes()}
          </IntercomProvider>
        ) : (
          routes()
        )}
        <ToastContainer
          position="bottom-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  };
  return render();
};
export default App;
