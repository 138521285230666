import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

const TinyEditor = (props) => {
  const { value, onChange } = props.input;
  return (
    <Editor
      value={value}
      init={{
        plugins: 'link image code',
        toolbar:
          'undo redo | bold italic | alignleft aligncenter alignright | link  ',
        menubar: '',
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        setup: (editor) => {
          //SOURCE: https://www.tiny.cloud/docs/demo/custom-toolbar-menu-button/
          // let toggleState = false;
          // editor.ui.registry.addButton('fieldsButton', {
          //   text: 'Fields',
          //   fetch: function (callback) {
          //     let items = [
          //       {
          //         type: 'menuitem',
          //         text: 'asdfasdf',
          //         onAction: function () {
          //           editor.insertContent('{{hkjhjk}}');
          //         }
          //       },
          //       {
          //         type: 'togglemenuitem',
          //         text: 'Toggle menu item',
          //         onAction: () => {
          //           toggleState = !toggleState;
          //         },
          //         onSetup: function (api) {
          //           api.setActive(toggleState);
          //           return function () {};
          //         }
          //       }
          //     ];
          //     callback(items);
          //   }
          // });
        }
      }}
      onEditorChange={(val) => {
        onChange(val);
      }}
    />
  );
};
export default TinyEditor;
